import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({siteTitle}) => (
    <header>
        <div className={"header-container"}>
            <div className={"top-menu"}>
                <Link className={"logo"}to="/" title={"MoodFood"}>
                  {"MoodFood"}
                </Link>
                <div className={"get-started"}>
                    <a href={"https://moodfoodtech.typeform.com/to/XdDzF1fe"}>{"Request Demo"}</a>
                </div>
            </div>
        </div>
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
